import { createTypes } from 'reduxsauce'

export default createTypes(`
SET_ACCESS_TOKEN
GENERIC_FAILURE
RESET_FETCHING_FLAG
RESET_TABLE_LOADING
RESET_SUMMARY_MODAL_STATES
RESET_COLLECTION_DETAILS_LOADING
GENERIC_SUCCESS
REQUEST_GET_ALL_COLLECTIONS_API
GET_ALL_COLLECTIONS
GET_ALL_COLLECTIONS_FAIL
REQUEST_GET_ALL_COMPANIES_TABLE_DATA_API
GET_ALL_COMPANIES_TABLE_DATA
GET_ALL_COMPANIES_TABLE_DATA_FAIL
REQUEST_GET_COLLECTION_DETAILS_API
GET_COLLECTION_DETAILS
GET_COLLECTION_DETAILS_FAIL
REQUEST_SET_TABLE_COLLAPSED_API
SET_TABLE_COLLAPSED
SET_TABLE_COLLAPSED_FAIL
REQUEST_SET_TABLE_EXPANDED_API
SET_TABLE_EXPANDED
SET_TABLE_EXPANDED_FAIL
REQUEST_GET_FAVOURITES_API
GET_FAVOURITES
GET_FAVOURITES_FAIL
REQUEST_SET_PREFERENCES_API
SET_PREFERENCES
SET_PREFERENCES_FAIL
REQUEST_GET_PREFERENCES_API
GET_PREFERENCES
GET_PREFERENCES_FAIL
REQUEST_GET_IMAGE_API
GET_IMAGE
GET_IMAGE_FAIL
REQUEST_GET_EQUITY_SUMMARY_API
GET_EQUITY_SUMMARY
GET_EQUITY_SUMMARY_FAIL
REQUEST_GET_QUOTE_API
GET_QUOTE
GET_QUOTE_FAIL
REQUEST_GET_TOOLTIP_DEFINITIONS_API
GET_TOOLTIP_DEFINITIONS
GET_TOOLTIP_DEFINITIONS_FAIL
REQUEST_GET_COLLECTION_HIGHLIGHTS_API
GET_COLLECTION_HIGHLIGHTS
GET_COLLECTION_HIGHLIGHTS_FAIL
REQUEST_SET_GENERATING_COLLECTION_COLLAPSED_API
SET_GENERATING_COLLECTION_COLLAPSED
SET_GENERATING_COLLECTION_COLLAPSED_FAIL
REQUEST_SET_GENERATING_COLLECTION_EXPANDED_API
SET_GENERATING_COLLECTION_EXPANDED
SET_GENERATING_COLLECTION_EXPANDED_FAIL
SET_USER_HASH
`)
