/* eslint-disable */
/**
 * Method used to get a query string parameter value
 * @param {URL parameter key} key
 * @return String
 */
export const getQueryStringParameterValue = key => {
  if (!window.location.search) return undefined

  const params =
    (
      /^[?#]/.test(window.location.search)
        ? window.location.search.slice(1)
        : window.location.search
    )
      .split('&')
      .reduce((
        parameters,
        param
      ) => {
        const [keyIndex, value] = param.split('=')
        parameters[keyIndex] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        return parameters
      }, {})
  
    if (!params || Object.keys(params).length < 1) return undefined
  
    return params[key]
  }

  /**
 * Method used to send tracking event to adobe analytics
 * @param {{interactionName: string, stockCode: string, virtualPageName: string}} Obj
 * @param {string} Obj.interactionName interaction name to be set in prop44
 * @param {string} Obj.stockCode stock code name to be set in eVar10
 * @param {string} Obj.virtualPageName virtual page name to be set in prop43
 */
  export const trackEvent = ({
    interactionName = '', 
    stockCode = '', 
    virtualPageName = (window.s && window.s.prop43) || ''  
  } = {}) => {
    if (interactionName) {
      let event = {
        prop44: interactionName,
        eVar10: stockCode,
        prop43 : virtualPageName
      }
      let s = window.s
      if (s && s.prop2) {
        s.eVar5 = '' // Internal campaigns, it will fire only on page load
        s.prop16 = '' // Previous page, it will fire only on page load
        s.t(event)
      }
    }
  }
