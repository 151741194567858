/* eslint-disable */
import Types from "../actions/types";
import MarkitApiService from "../services/markit-api-service";
import { getQueryStringParameterValue } from "../utils/helper";
import { put, fork, call, take, all } from "redux-saga/effects";
import {
  setAccessToken,
  onGenericApiFailure,
  onGenericApiSuccess,
  setUserHash,
} from "../actions/commonActions";
import { getApiTypes } from "./getApiTypes";
import {
  fetchToken,
  redirectOnTokenError,
  tokenInitialLoad,
} from "../utils/authApiService";
import Cookies from "js-cookie";

// Use a 'watcher/worker' pattern to listen for and handle redux Actions
export default () => {
  let api = null;
  let url = null;

  function getApiFunction(httpMethod) {
    let apiType;

    switch (httpMethod) {
      case "POST":
        apiType = api.postData;
        break;
      case "PUT":
        apiType = api.putData;
        break;
      case "DELETE":
        apiType = api.deleteData;
        break;
      case "GET":
      default:
        apiType = api.getData;
    }

    return apiType;
  }

  function apiUrl(action) {
    if (
      action.ajaxType == "GET" &&
      (action.endPoint.indexOf("/collections") !== -1 || action.endPoint.indexOf("/allCollectionsSymbolMap") !== -1) &&
      action.endPoint.indexOf("/favorites") == -1 &&
      action.endPoint.indexOf("/prefrences") == -1 &&
      window.location.host.indexOf("commsec.com.au") > -1
    )
      return window.MD.CDN_API_URL;
    else return window.MD.API_URL;
  }

  // Worker
  function* worker(action) {
    let accessToken = "";
    let responseCookie = Cookies.get("ModToken") || Cookies.get('MODJSONWebToken');
    let ModToken = undefined;
    let userHash;
    let queryParamToken = getQueryStringParameterValue("access_token");
    if (
      !responseCookie &&
      window.location.host.indexOf("commsec.com.au") > -1 &&
      (queryParamToken === undefined ||
        queryParamToken === null ||
        queryParamToken === "")
    ) {
      yield fetchToken(false, false)
        .then(() => {
          responseCookie = Cookies.get("ModToken") || Cookies.get('MODJSONWebToken');
        })
        .catch((err) => {
          redirectOnTokenError(err);
        });
    }
    if (responseCookie) {
      responseCookie = responseCookie.split("-")
      ModToken = responseCookie[0];
      userHash = responseCookie[1]
      yield put(setUserHash(userHash));
    }
    else if(queryParamToken){
      queryParamToken = queryParamToken.split("-")
      ModToken = queryParamToken[0]
      userHash = queryParamToken.length > 1 ? queryParamToken[1] : ''
      if(userHash){
        yield put(setUserHash(userHash));
      }
    }
    if (ModToken !== undefined && ModToken !== null && ModToken !== "") {
      accessToken = ModToken;
    } 
    api = MarkitApiService.create(apiUrl(action), accessToken);
    yield put(setAccessToken(accessToken));
    //  yield multiWorker(action);
    if (action.type === "GET_MARKETS_API") {
      yield multiWorker(action);
    } else {
      let endPoint = action.endPoint;
      let parameters = action.params;
      let successAction = action.onSuccess || onGenericApiSuccess;
      let failureAction = action.onFailure || onGenericApiFailure;
      let httpMethod = action.ajaxType;

      //To prevent caching issue in IE11
      if (
        httpMethod == "GET" &&
        !!window.navigator.msSaveOrOpenBlob &&
        !!document.documentMode
      ) {
        endPoint =
          endPoint.indexOf("?") > -1
            ? endPoint + "&" + new Date().getTime()
            : endPoint + "?" + new Date().getTime();
      }
      let apiType = getApiFunction(httpMethod);

      try {
        let response;
        let x;
        if (action.type === Types.GET_URLBASE_API) {
          response = { data: { url: url }, ok: true };
        } else {
          if (action.type === Types.API_REQUEST_DECODE_API) {
            response = yield parameters.key.map((p) =>
              call(apiType, endPoint, p)
            );
          } else {
            response = yield call(apiType, endPoint, parameters);
            if (
              response.status === 401 &&
              window.location.host.indexOf("commsec.com.au") > -1
            ) {
              //retry for token once
              tokenInitialLoad(true, true, true);
            }
          }
        }

        if (response.ok) {
          // 'data' keys the entire response object... always.
          yield put(successAction(response.data));
        } else if (action.type === Types.API_REQUEST_DECODE_API) {
          yield put(successAction(response));
        } else {
          yield put(failureAction(response));
        }
      } catch (error) {
        yield put(failureAction(error));
      }
    }
  }

  // Watcher
  function* watcher() {
    const typesArray = getApiTypes();
    while (true) {
      const action = yield take(typesArray);
      if (!action) break;
      yield fork(worker, action);
    }
  }

  return {
    watcher,
  };
};
