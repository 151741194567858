import Axios from 'axios'
import Cookies from 'js-cookie'
import Moment from 'moment-timezone'

let tokenApiCalled = false

export function fetchToken (renew = false, override = true) {
  if (!tokenApiCalled || override) {
    tokenApiCalled = true
    let tokenUrl =
      window.MD.ENV === 'production'
        ? 'https://www2.commsec.com.au/core/v1/token'
        : 'https://www2.staging.commsec.com.au/core/v1/token'
    return Axios.get(tokenUrl + (renew ? '?renew=true' : ''), {
      withCredentials: true
    })
  } else {
    let err = new Error('Duplicate Request')
    err.status = 999
    return Promise.reject(err)
  }
}

export function tokenInitialLoad (
  redirection = true,
  refreshPage = false,
  renew = false
) {
  let ModToken
  fetchToken(renew)
    .then((res) => {
      ModToken = Cookies.get('ModToken') || Cookies.get('MODJSONWebToken')
      refreshPage && window.location.reload()
    })
    .catch((err) => {
      ModToken = redirection ? redirectOnTokenError(err) : undefined
    })
  return ModToken
}

export function redirectOnTokenError (err) {
  let ModToken
  let redirectionUrl = getRedirectionUrl()
  if (redirectionUrl) {
    if (err && err.status) {
      if (err.status === 401) {
        window.location.href = redirectionUrl
      } else if (err.status === 500) {
        for (let i = 1; i <= 3; i++) {
          ModToken = tokenInitialLoad(false)
          if (ModToken) break
        }
        if (!ModToken) {
          window.location.href = redirectionUrl
        }
      }
    } else {
      window.location.href = redirectionUrl
    }
  }
  return ModToken
}

export function isTokenValid () {
  let isValid
  let responseCookie = Cookies.get('ModToken') || Cookies.get('MODJSONWebToken')
  responseCookie = responseCookie && responseCookie.split('-')
  if (responseCookie && responseCookie[2] && responseCookie[3] && responseCookie[4]) {
    let cookieExpiry = new Moment(
      decodeURIComponent(
        `${responseCookie[2]}-${responseCookie[3]}-${responseCookie[4]}`
      )
    )
      .utc()
      .format('hh:mm:ss A, DD MMM YYYY')
    cookieExpiry = new Moment(cookieExpiry, 'hh:mm:ss A, DD MMM YYYY')
      .subtract(60, 'minutes')
      .format('hh:mm:ss A, DD MMM YYYY')
    let presentUTC = new Moment().utc().format('hh:mm:ss A, DD MMM YYYY')
    isValid = new Moment(cookieExpiry).isAfter(presentUTC)
  }
  return isValid
}

function getRedirectionUrl () {
  let url
  if (
    window.location.host.indexOf('research.staging.commsec') > -1 ||
    window.location.host.indexOf('research.commsec.com') > -1
  ) {
    url = `${window.MD.COMMSEC_URL}Public/HomePage/Login.aspx?r=${window.MD.COLLECTIONS_MOD_URL}`
  } else if (
    window.location.host.indexOf('www2.staging.commsec') > -1 ||
    window.location.host.indexOf('www2.commsec.com') > -1
  ) {
    url = `${
      window.MD.COMMSEC_URL
    }Public/HomePage/Login.aspx?r=${encodeURIComponent(
      window.MD.COLLECTIONS_COMMSEC_URL
    )}`
  }
  return url
}
