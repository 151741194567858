/* eslint-disable */
// This is the root reducer in which all other reducers are registered.
// Naming convention is to use index.js
import {combineReducers} from 'redux'
import commonReducer from './commonReducer'

const rootReducer = combineReducers({
	commonReducer
})

export default rootReducer