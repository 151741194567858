// a library to wrap and simplify api calls
import apisauce from 'apisauce'

const create = (baseURL, accessToken) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    // 50 second timeout...
    timeout: 50000
  })

  const getData = (endPoint, parameters) => api.get(endPoint, parameters, {withCredentials: true})
  const postData = (endPoint, parameters) => api.post(endPoint, parameters, {withCredentials: true})
  const putData = (endPoint, parameters) => api.put(endPoint, parameters, {withCredentials: true})
  const deleteData = (endPoint, parameters) => api.delete(endPoint, parameters, {withCredentials: true})
  return {
    // a list of the API functions from step 2
    getData,
    postData,
    putData,
    deleteData
  }
}

// let's return back our create method as the default.
export default {
  create
}
