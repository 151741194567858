import {useEffect} from 'react'

function Error () {
    useEffect(() => {
      window.location.href = window.MD.ERROR_PAGE
    }, [])

  return null
}

export default Error
