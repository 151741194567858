/* eslint-disable */
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers/rootReducers'
import rootSaga from '../sagas/initializer'
import Raven from "raven-js"

export default function configureStore () {
  let myState
  const sagaMiddleware = createSagaMiddleware({
        onError: function(error) {
        Raven.captureException(error, {
            tags: {type: 'redux-saga'}
        });
        },
        logger: function(level, ...args) {
          if (level === 'error') {
            Raven.captureMessage(args.join(' '), {
              tags: {type: 'redux-saga'}
            });
          }
        }
    });
  const middleware = applyMiddleware(sagaMiddleware)
  const enhancer = compose(middleware)
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(rootReducer, myState, composeEnhancers(enhancer))

  // run sagas
  sagaMiddleware.run(rootSaga)

  return store
}